.PortfolioPage{
    .portfolios{
        display: grid;
        grid-template-columns:repeat(2, 1fr);
        gap: 1rem 1.5rem;
        @media screen and (max-width: 1000px){
            grid-template-columns:repeat(1, 1fr);
            
        }
        .image-data{
            position: relative;
            &::before{
                position: absolute;
                content: "";
                top: 12px;
                left: 15px;
                height: calc(100% - 30px);
                width: calc(100% - 30px);
                background-color: white;
                opacity: 0.5;
                transform-origin: center;
                transform: scale(0);
                transition: all 0.4s ease-in-out;
            }
            &:hover::before{
                transform: scale(1);
            }
            img{
                width: 100%;
                height: 30vh;
                object-fit: cover;
            }
    
            .hover-item{
                list-style: none;
                position: absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
                // background-color: slateblue;
                // margin: 1rem 2rem;
                display: flex;
                visibility: hidden;
                li{
                    a{
                        padding: 1rem;
                        text-decoration: none;
                        font-family: inherit;
                        border-radius: 10px;
                        background-color: #037fff;
                        color: white;
                        &:not(:last-child){
                            margin-right: 2rem;
                        }
                    }
                }
            }
            &:hover .hover-item{
                visibility: visible;
                top: 50%;
            }
            @media screen and (max-width: 1000px){
                height: 30rem;
                img{
                    height: 100%;
                }
            }
        }
        
    
        h5{
            font-size: 1.7rem;
            font-weight: bold;
            color: #fff;
            transition: all 0.3s ease-in-out;
            &:hover{
                color: #037fff;
                cursor: pointer;
            }
        }
    }
}

.portfolio-btn{
    padding: .6rem 2rem;
    margin: .2rem .5rem;
    background-color: #0381ff6c;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    color: white;
    transition: all 0.3s ease-in-out;
    &:hover{
        background-color: #037fff;
    }
    &:active{
        background-color: #037fff;
    }
}


.buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem 0;

}