@import './styles/layout';
@import './styles/navbar';
@import './styles/typography';
@import './styles/tittle';
@import './styles/about';
@import './styles/skills';
@import './styles/services';
@import './styles/blog';
@import './styles/contact';
@import './styles/portfolios';

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body::-webkit-scrollbar {
    width: 8px;
}
body::-webkit-scrollbar-thumb{
    background-color: #0381ffe5;
}
body::-webkit-scrollbar-track{
    background-color: #a4acc46c;
}
body{
    color: #a4acc4;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 1.2rem;
}

span{
    color: #037fff;
}

@media screen and (max-width: 411px){
    html{
        font-size: 70%;
    }
}
@media screen and (min-width: 412px) and (max-width: 700px){
    html{
        font-size: 80%;
    }
}