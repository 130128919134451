.ImageSection{
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;

    .img{
        width: 100%;
        height: 80vh;
        img{
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    .about-info{
        margin-left: 2rem;
        h4{
            font-size: 2rem;
        }
        .about-text{
            padding: 1rem 0;
        }
    }

    .about-details{
        display: flex;
        p{
            padding: 0.3rem 0;
        }
        .right-section{
            margin-left: 2rem;
        }
    }

    @media screen and (max-width: 1400px){
        flex-direction: column;
        .about-info{
            margin-left: 0;
            margin-top: 1rem;
        }
    }
    
}

.btn{
    padding: .5rem 1rem;
    background-color: #037fff;
    color: #fff;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    text-transform: uppercase;
    cursor: pointer;
    // letter-spacing: 2px;
    margin-top: 1rem;
    position: relative;
    &::after{
        position: absolute;
        content: '';
        width: 0;
        height: 0.3rem;
        left: 0;
        bottom: 0;
        background-color: #a4acc4;
        transition: all 0.3s ease-in-out;
    }
    &:hover::after{
        width: 100%;
    }
}

.skills-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 1100px){
        grid-template-columns: repeat(1, 1fr);
    }
}

