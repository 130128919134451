.HomePage{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.hero-text{
    color: white;
    text-align: center;
    font-size: 3.6rem;
    span{
        color: #037fff;
    }
    .h-sub-text{
        text-align: center;
    }
}

.resume-btn{
    display: flex;
    justify-content: center;
}

.icons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    .icon-holder{
        margin-right: 1rem;
        .icon{
            font-size: 2.5rem;
            color: #a4acc4;
            padding: 1px;
            transition: all .3s ease-in-out;
        }
        .fb:hover{
            color: #0c53ec;
        }
        .li:hover{
            color: #12618b;
        }
        .gh:hover{
            color: #000;
        }
    }
}