.BlogPage{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    @media screen and (max-width: 1000px){
        grid-template-columns: repeat(1, 1fr);
    }
    // margin-top: 5rem;
    .blog{
        background-color: #161925;
        width: 100%;
        padding: 1rem;
        overflow: hidden;
        .blog-content{
            overflow: hidden;
            padding-bottom: 2rem;
            img{
                width: 100%;
                transition: all 0.2s ease-in-out;
                &:hover{
                    transform: scale(1.1);
                }
                padding-bottom: 2rem;
            }
            .blog-link{
                color: inherit;
                font-family: inherit;
                text-decoration: none;
                font-size: 2rem;
                padding-bottom: 2rem;
                transition: all 0.3s ease-in-out;
                &:hover{
                    color: #037fff;
                }
            }
        }
    }
}

.blog-title{
    margin-bottom: 5rem;
}

.blog-coming{
    text-align: center;
}