.App{
    .sidebar{
        width: 16%;
        height: 100vh;
        background-color: #191d2b;
        position: fixed;
        z-index: 1000;
        transform-origin: left;
        border-right: 5px solid #2e344e;
        @media screen and (max-width: 1000px){
            width: 30%;
            transform: translateX(-100%);
            transition: all 0.5s ease-in-out;
        }
        @media screen and (max-width: 411px){
            width: 50%;

            
        }
    }
    .nav-toggle{
        @media screen and (max-width: 1000px){
            transform: translateX(0);
            transition: all 0.5s ease-in-out;
            // width: 30%;
        }
    }
    .main-content{
        width: 84%;
        margin-left: 16%;
        min-height: 100vh;
        background-color: #10121b;
        // background-image: url(https://images.unsplash.com/photo-1536589961747-e239b2abbec2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80);
        // background-image: url("/./src/img/dots.svg");
        background-image: url(../img/dots.svg);
        display: grid;
        position: relative;
        .content{
            margin: 5rem 10rem;
            @media screen and (max-width: 1100px){
                margin: 2rem 5rem;
            }
        }
        
        @media screen and (max-width: 1000px){
            margin-left: 0;
            width: 100%;

        }
    }
}

.nav-btn{
    position: absolute;
    z-index: 10;
    top: 10%;
    right: 10%;
    // background-color: red;
    width: 4rem;
    height: 4rem;
    display: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    .line-1, .line-2, .line-3{
        width: 100%;
        height: .4rem;
        background-color: #fff;
        display: none;
        border-radius: 20px;
        pointer-events: none;
        &:not(:last-child){
            margin-bottom: .5rem;
        }
    }
    @media screen and (max-width: 1000px){
        display: block;
        .line-1, .line-2, .line-3{
            display: block;
        }
    }
}