.ContactItem{
    width: 100%;
    &:not(:last-child){
        margin-bottom: 1rem;
    }
    .contact{
        display: flex;
        align-items: center;
        height: 10rem;
        background-color: #191d2b;
        padding: 3rem 0;
        .right-items{
            margin-left: 2rem;
        }
        img{
            padding: 1rem;
            border: 1px solid #2e344e;
            margin-left: 2rem;
            width: 16%;
        }
    }
}

.ContactPage{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 4rem;
    .contact-section{
        width: 100%;
    }
    @media screen and (max-width: 1000px){
        grid-template-columns: repeat(1, 1fr);
    }
}

.map-section{
    width: 97%;
    padding: 1rem;
    background-color: #191d2b;
    iframe{
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 1000px){
        margin-bottom: 2rem;
        height: 30rem;
    }
}

.contact-section{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.title{
    margin-bottom: 5rem;
}