.ServicesSection{
    // margin-top: 2rem ;
    .service{
        width: 95%;
        text-align: center;
        background-color: #191d2b;
        border-left: 1px solid #2e344e;
        border-right: 1px solid #2e344e;
        border-bottom: 1px solid #2e344e;
        border-top: 8px solid #2e344e;
        transition: all .4s ease-in-out;
        @media screen and (max-width: 1400px){
            // flex-direction: column;
                margin-bottom: 2rem;
        }
        &:hover{
            border-top: 8px solid #037fff;
        }
        .service-content{
            padding: 1rem;
            .service-title{
                font-size: 1.8rem;
                font-weight: 500;
                position: relative;
                padding-bottom: 1rem;
                margin: 1rem 0;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 30%;
                    height: 1px;
                    background-color: #2e344e;
                }
            }
        }
    }
}

.services-container{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1400px){
        flex-direction: column;
            margin-bottom: 2rem;
    }
}