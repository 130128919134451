.SkillsSection{
    margin: 1rem 0;
}

.skill-container{
    // margin: 2rem;
    .skills-title{
        font-size: 1.4rem;
        font-weight: 400;
    }
    .skills-bar{
        display: flex;
        align-items: center;
        // .skills-text{}
        .skills-progress{
            // margin-top: 5px;
            position: relative;
            width: 100%;
            .progress{
                position: absolute;
                width: 100%;
                height: 0.5rem;
                background-color: #0381ff3f;
                margin-left: 1rem;
                .inner-progress{
                    position: absolute;
                    height: 0.5rem;
                    background-color: #037fff;
                    // margin-left: 1rem;
                }
            }

        }
    }
}